@import "../../../environments/includes.scss";

.me {
  width: 100%;
  height: 100vh;

  background: #fff;

  position: relative;

  .row {
    @include max-width($max-width);

    height: 100%;

    padding: 40px;

    @include flex(row, center);

    .photo-container {
      .me-img {
        width: 80%;
        margin: 50px;
        //box-shadow: 20px 20px 0 0 #000;
      }
    }

    .text-container {
      .me-title {
        font-size: 50px;
        font-weight: bold;
      }

      .me-description {
        margin: 40px 0;
      }

      .me-btn {
        @include flex(row, left);
        flex-wrap: wrap;

        margin-bottom: 10px;

        .cv-btn,
        .contact-me-btn {
          width: 100%;

          margin-bottom: 16px;

          position: relative;

          text-decoration: none;
          text-align: center;

          padding: 16px 16px;

          border: 1px solid #fff;

          @media screen and (min-width: 768px) {
            width: 200px;

            margin: 0;
            margin-right: 16px;

            border: 1px solid #000;
          }
        }

        .cv-btn {
          color: #fff;

          transition: $transition-time;

          @media screen and (min-width: 768px) {
            color: #000;
          }

          &:hover {
            color: #000;
            background: #fff;

            @media screen and (min-width: 768px) {
              color: #fff;
              background: #000;
            }
          }
        }

        .contact-me-btn {
          color: #000;
          background: #fff;

          transition: $transition-time;

          @media screen and (min-width: 768px) {
            color: #fff;
            background: #000;
          }

          &:hover {
            color: #fff;
            background: none;
            border: 1px solid #fff;

            @media screen and (min-width: 768px) {
              color: #000;
              background: #fff;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    @include background("../assets/images/me.webp", initial);

    > .row {
      background: rgba(0, 0, 0, 0.8);
    }

    .row {
      min-height: 100vh;
      padding: 20px;

      .photo-container {
        display: none;
      }

      .text-container {
        color: #fff;

        .me-btn {
          a {
            border-color: #fff;
          }

          .cv-btn {
            color: #fff;
          }

          .contact-me {
            color: #000;
            background: #fff;
          }
        }
      }
    }
  }
}
