@import "../../../environments/includes.scss";

.contact-form-container {
  @include flex("column", "center");

  height: 100vh;

  color: #fff;
  background: #000;

  position: relative;

  .row {
    @include max-width($max-width);

    .contacts {
      .contact-form-title {
        margin-bottom: 40px;
      }

      .contact-form-description {
        user-select: text;

        .contact-form-description-title {
          font-weight: bold;
        }
      }
    }

    .contact-form {
      .contact-form-input,
      .contact-form-textarea,
      .contact-submit-button {
        width: 100%;
        margin: 10px auto;
        padding: 10px;
      }

      .contact-submit-button {
        color: #fff;
        background: none;
        border: 1px solid #fff;
        transition: $transition-time;

        &:hover {
          color: #000;
          background: #fff;
        }
      }
    }
  }
}
