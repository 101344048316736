@import "../../../environments/includes.scss";

.idea {
  width: 100vw;
  height: 100vh;

  @include flex(row, center);

  color: #fff;

  position: relative;

  .container {
    width: 80%;
    height: 80%;

    @include max-width($max-width);

    @include flex(row, center);

    @include background("../assets/images/sfondi/realizza_idee.webp", initial);

    .idea-title {
      width: 80%;
      text-align: center;
      font-size: 60px;
      font-weight: bold;

      color: #a6623f;
      background: #fff;

      padding: 10px 16px;
    }
  }

  @media screen and (max-width: 768px) {
    .container .idea-title {
      font-size: 40px;
    }
  }
}
