@import "../../../environments/includes.scss";

.portfolio {
  @include flex(row, center);

  //margin-bottom: 100px;

  position: relative;

  #portfolio-title {
    margin: 50px 0;
    padding: 10px 40px;
    color: #1994bd;
    background: #fff;
    font-size: 60px;
  }

  @media screen and (max-width: 768px) {
    #portfolio-title {
      font-size: 40px;
    }
  }

  .portfolio-titles {
    text-align: center;
    font-weight: bold;
  }

  .container {
    width: 100%;
    height: 100%;

    color: #fff;

    @include max-width($max-width);

    @include flex(column, center);

    background: #1994bd;

    #row-title {
      width: 260px;
      max-width: 80%;
      text-align: center;
      padding: 10px;
      border-bottom: 3px solid #fff;
    }

    .row {
      justify-content: center;
      text-align: center;
      padding: 30px 0;

      a {
        color: #fff;
        text-decoration: none;
        transition: $transition-time;

        img {
          width: 100%;
        }

        p {
          padding: 10px 0;
        }

        @media screen and (min-width: 992px) {
          &:hover {
            width: 30%;
          }
        }
      }
    }
  }
}
