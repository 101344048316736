@import "../../../environments/includes.scss";

.services {
  height: 100vh;

  /* @media screen and (min-width: 768px) {
    height: 100%;
  } */

  .services-title {
    width: 80%;
    margin: 0 auto;
    padding: 48px 0;
    font-size: 60px;
    text-align: center;
    color: #fff;
    font-weight: bold;
  }

  background: #181818;

  position: relative;
  z-index: 2;

  .row {
    justify-content: center;

    .service-content-container {
      margin: 80px 0 120px 0;
      text-align: center;
      background: #fff;
      transition: $transition-time;

      position: absolute;

      @media screen and (min-width: 992px) {
        position: relative;
      }

      .service-content {
        width: 80%;
        margin: 0 auto;

        .service-title {
          margin-bottom: 20px;
          font-size: 24px;
          font-weight: bold;
        }

        .service-description {
          padding-bottom: 20px;
        }
      }
    }

    img {
      width: 80%;
      position: relative;
      bottom: 30px;
    }
  }

  @media screen and (max-width: 992px) {
    .services-title {
      font-size: 40px;
    }
  }

  @media screen and (min-width: 992px) {
    .row > div:hover {
      width: 30%;
    }
  }
}
