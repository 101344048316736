@import "../../../environments/includes.scss";

.loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  position: fixed;
  background: #1a212b;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10000;
}

.loader_img {
  max-width: 30%;
  max-height: 40%;
}
