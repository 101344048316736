@import "../../../environments/includes.scss";

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 0;
  
  background: #1e1e1e;
  
  line-height: 0;
  padding: 20px 0;

  position: relative;

  .footer-description {
    margin: 0;
    color: #fff;
    padding: 20px;
    -webkit-appearance: none;
    appearance: none;

    .footer-description-link {
      color: #bbb;
      text-decoration: underline;

      transition: $transition-time;

      &:hover {
        color: #fff;
      }
    }
  }

  .footer-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-link {
      text-decoration: none;
      margin: 10px;

      .footer-link-icon {
        color: #fff;
      }

      .ludelist_logo {
        width: 20px;
      }
    }
  }
}
