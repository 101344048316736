@import "../../../environments/includes.scss";

.activity {
  width: 100%;
  height: 100vh;

  position: relative;

  .row {
    @include flex(row, right);

    //@include max-width($max-width);
    height: 100%;

    @include background("../assets/images/sfondi/activity.webp", initial);

    .activity-content {
      width: 90%;

      margin: 24px auto;

      padding: 112px 0;

      @media screen and (min-width: 768px) {
        width: 512px;
        margin-right: 80px;
      }

      @include flex(row, center);

      color: #fff;
      background: rgba(0, 0, 0, 0.7);

      .container {
        width: 80%;

        .activity-title {
          margin-bottom: 24px;

          font-size: 36px;
          font-weight: bold;

          @media screen and (min-width: 768px) {
            font-size: 48px;
          }
        }

        .activity-description {
          margin-bottom: 40px;
        }
      }
    }

    /* @media screen and (max-width: 1260px) {
      .container #activity-title {
        font-size: 60px !important;
      }
    } */
  }

  /* @media screen and (max-width: 992px) {
    height: auto;

    .row {
      min-height: 100vh;
      justify-content: center;

      .activity-content {
        width: 90%;
        margin: 20px auto;
        padding: 50px 0;

        text-align: center;
      }

      .container .activity-title {
        font-size: 50px !important;
      }
    }
  } */
}
