@import "../../../environments/includes.scss";

.hero {
  height: 100vh;
  @include background("../assets/images/sfondi/home.webp", initial);

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;

  text-align: center;

  .row {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);

    .logo {
      width: 200px;

      @media screen and (min-width: 768px) {
        width: 300px;
      }
    }

    .title,
    .subtitle {
      font-weight: bold;
    }

    .title {
      font-size: 36px;

      @media screen and (min-width: 768px) {
        font-size: 64px;
      }
    }

    .subtitle {
      margin-bottom: 40px;

      font-size: 22px;

      @media screen and (min-width: 768px) {
        font-size: 36px;
      }
    }
  }

  .discover-more {
    color: #fff;
    background: none;

    border: none;

    position: absolute;
    bottom: 8px;

    animation: discoverMoreAnimation 1s alternate infinite;

    @media screen and (min-width: 768px) {
      bottom: 24px;
    }
  }

  @keyframes discoverMoreAnimation {
    from {
      bottom: 8px;

      @media screen and (min-width: 768px) {
        bottom: 24px;
      }
    }

    to {
      bottom: 36px;

      @media screen and (min-width: 768px) {
        bottom: 48px;
      }
    }
  }
}
