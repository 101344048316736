$max-width: 1440px;
$transition-time: 0.5s;

@mixin flex($direction, $position) {
  display: flex;
  justify-content: $position;
  align-items: center;
  flex-direction: $direction;
}

@mixin background($url, $fixed) {
  background: url($url);
  background-attachment: $fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin max-width($max-width) {
  max-width: $max-width !important;
  margin: 0 auto !important;
}

input,
textarea,
a,
p,
span,
button {
  outline: none;
  text-decoration: none;
}

.row {
  width: 100% !important;
  margin: 0 !important;
}

* {
  user-select: none;
}

.go-to-services {
  color: #000;
  background: #fff;
  padding: 12px 16px;

  cursor: pointer;

  user-select: none;

  position: relative;
  bottom: 0;

  border: none;

  transition: $transition-time;

  &:hover {
    color: #fff;
    background: #000;

    bottom: 8px;
  }
}
